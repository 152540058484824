<!--
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-04-26 11:25:09
 * @LastEditors: zt zhoutao@ydmob.com
 * @LastEditTime: 2024-05-22 14:39:23
 * @FilePath: /mediatom-web/src/viewsForManage/Flow/AdplaceConfig/LeftMain/SourceCheckBox.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="source-checkbox" :class="{last: last}" :title="sourceInfo.dspName">
    <a-checkbox :disabled="disabled" @click.stop="$emit('checkedSource', sourceInfo.dspId)" :checked="checked"><span class="sts-dot" :class="{'open': sourceInfo.sts === 'A'}"></span>{{ sourceInfo.dspName }}</a-checkbox>
  </div>
</template>

<script>
export default {
  name: 'SourceCheckBox',
  computed: {
    checked () {
      return this.currentCheckedIdList.includes(this.sourceInfo.dspId)
    },
    disabled () {
      return this.checkedIdList.includes(this.sourceInfo.dspId)
    }
  },
  props: {
    sourceInfo: {
      default: () => ({}),
      type: Object
    },
    checkedIdList: {
      default: () => ([]),
      type: Array
    },
    currentCheckedIdList: {
      default: () => ([]),
      type: Array
    },
    last: {
      default: false,
      type: Boolean
    }
  }
}
</script>

<style lang="less" scoped>
.source-checkbox {
  width: 100%;
  box-sizing: border-box;
  padding-left: 14px;
  padding-top: 10px;
  padding-bottom: 10px;
  &.last {
    padding-bottom: 0;
  }
  .sts-dot{
    display: inline-block;
    width: 6px;
    height: 6px;
    background-color: @compDisabledColor;
    border-radius: 50%;
    vertical-align: middle;
    margin: 0 4px 0 0;
    margin-top: -2px;
    &.open{
      background-color: @assisColor;
    }
  }
}
::v-deep{
  .ant-checkbox-wrapper{
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    span:last-child{
      max-width: 240px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>
